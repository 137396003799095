import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Text from "@ui/Text/Text"
import CenterLayout from "@components/centerlayout"
import { DividerIllustration } from "../components/DividerIllustration"
import Box from "@ui/Box"
import Link from "@ui/Link/Link"
import Parallax, { ImgTitle, ImgCopyright } from "@components/parallax"
import DividerLine from "@components/DividerLine"
import { rem } from "polished"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { graphql } from "gatsby"

interface Props {
  data: {
    sustainableFuture: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  }
}

const RelevantTools = ({ data }: Props) => (
  <Layout>
    <SEO title="Relevant Tools" />
    <DividerIllustration loading="eager" />
    <CenterLayout my="5xl">
      <Box maxWidth="80ch">
        <Text size="md" as="h1" fontWeight="bold" mb="lg">
          Relevant Tools
        </Text>
        <Text size="md" as="p" mb="xl">
          Here we have gathered all relevant set of integrated tools to assess
          and visualize the alignment of National Development Plans with Agenda
          2030 and Agenda 2063 and assess progress towards achieving them.
        </Text>
        <DividerLine my="xl" />
        <Text size="2xl" as="h2" fontWeight="bold" my="xl">
          Templates
        </Text>
        <Text fontWeight="bold" size="md" mt="xl">
          Module 1
        </Text>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Link href="/module_assets/templates/module_1/1.1_national_development_plan_concept_note_template.docx">
            1.1_National Development Plan Concept Note Template.docx
          </Link>
          <Link href="/module_assets/templates/module_1/1.2_work_plan_template.docx">
            1.2_Work Plan Template.docx
          </Link>
          <Link href="/module_assets/templates/module_1/1.3_stakeholder_analysis_template.docx">
            1.3_Stakeholder Analysis Template.docx
          </Link>
          <Link href="/module_assets/templates/module_1/1.4_stakeholder_engagement_plan_template.docx">
            1.4_Stakeholder Engagement Plan Template.docx
          </Link>
          <Link href="/module_assets/templates/module_1/1.5_institutional_planning_structure_for_the_development_of_the_ndp_template.docx">
            1.5 Institutional Planning Structure for the Development of the NDP
            Template.docx
          </Link>
          <Link href="/module_assets/templates/module_1/1.6_communication_plan_template.docx">
            1.6 Communication Plan Template.docx
          </Link>
        </Box>

        <Text fontWeight="bold" size="md" mt="xl">
          Module 2
        </Text>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Link href="/module_assets/templates/module_2/2.1_template_national_development_vision_scoping_exercise.docx">
            2.1 Template_ National Development Vision Scoping Exercise.docx
          </Link>
          <Link href="/module_assets/templates/module_2/2.3_template_national_development_visioning_exercise.docx">
            2.3 Template_National Development Visioning Exercise.docx
          </Link>
        </Box>

        <Text fontWeight="bold" size="md" mt="xl">
          Module 3
        </Text>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Link href="/module_assets/templates/module_3/3.3_data_source_mapping_template.docx">
            3.3 Data Source Mapping Template.docx
          </Link>
          <Link href="/module_assets/templates/module_3/3.6_rapid_peace_and_justice_context_review_template.docx">
            3.6 Rapid Peace and Justice ConLink Review Template.docx
          </Link>
        </Box>

        <Text fontWeight="bold" size="md" mt="xl">
          Module 5
        </Text>
        <Link href="/module_assets/templates/module_5/5.4_template_for_an_integrated_strategic_plan.docx">
          5.4 Template_for an integrated Strategic Plan.docx
        </Link>

        <Text fontWeight="bold" size="md" mt="xl">
          Module 6
        </Text>
        <Link href="/module_assets/templates/module_6/6.3_suggested_template_for_the_operational_plan_plan.docx">
          6.3 Suggested template for the Operational Plan Plan.docx
        </Link>

        <DividerLine my="xl" />
        <Text size="2xl" as="h2" fontWeight="bold" my="xl">
          Tools and Resources
        </Text>
        <Text size="xl" as="h3" fontWeight="bold" my="lg">
          Resources on the SDGs and Agenda 2063
        </Text>
        <Link href="https://www.nepad.org/publication/agenda-2063-framework-document">
          Agenda 2063 Framework Document
        </Link>
        <Text size="md" as="p" mb="xl">
          Full text of Agenda 2063 including its background, aspirations, goals,
          priority areas and targets, indicative strategies, critical factors
          for success, and implementation arrangements.
        </Text>
        <Link href="https://au.int/sites/default/files/documents/33126-doc-11_an_overview_of_agenda.pdf">
          AU First Ten Year Implementation Plan
        </Link>
        <Text size="md" as="p" mb="xl">
          Implementation plan for the 2013-2023 period of Agenda 2063, including
          key transformational outcomes to be reached, monitoring and
          evaluation, partnerships and financing goals.
        </Text>
        <Link href="https://au.int/en/agenda2063/overview">
          AU Agenda 2063 web site
        </Link>
        <Text size="md" as="p" mb="xl">
          The web site is a full repository of documents on Agenda 2063. It also
          includes information on Continental Frameworks, National and Regional
          Economic Commissions development priorities, and Flagship Projects.
        </Text>
        <Link href="https://www.nepad.org/agenda-dashboard">
          AUDA-NEPAD Dashboard for the SDGs and Agenda 2063
        </Link>
        <Text size="md" as="p" mb="xl">
          The interactive dashboard presents data on AU achievement on both
          Agenda 2063 and the SDGs at continental, regional, and national levels
        </Text>
        <Link href="https://sdgs.un.org/goals ">
          UN Department of Economic and Social Affairs web site
        </Link>
        <Text size="md" as="p" mb="xl">
          The UNDESA portal contains information on the SDGs, as well as events,
          publications, and a comprehensive repository of documents and
          resources on the Goals.
        </Text>
        <Link href="https://www.un.org/sustainabledevelopment/">
          United Nations Sustainable Development Web site
        </Link>
        <Text size="md" as="p" mb="xl">
          The site offers information on the SDGs and a wide range of resources
          for campaigns, civic engagement, and action to support the SDGs.
        </Text>
        <Text size="xl" as="h3" fontWeight="bold" mt="4xl" mb="lg">
          Tools for Agenda 2063 and SDG alignment, integration and progress
          assessments
        </Text>
        <Link href="https://iprt.uneca.org/">
          Integrated Planning and Reporting Toolkit (IPRT)
        </Link>
        <Text size="md" as="p" mb="xl">
          Sponsored by United Nations Economic Commission for Africa (ECA), the
          IPRT helps planners adopt and integrate the 2030 Agenda and Agenda
          2063 into their NDP and report progression in a harmonised way. The
          IPRT is an online tool that can be used by planners from early
          planning to reporting.
        </Text>
        <Link href="https://www.undp.org/content/undp/en/home/librarypage/sustainable-development-goals/rapid-integrated-assessment---mainstreaming-sdgs-into-national-a.html">
          Rapid Integrated Assessment (RIA)
        </Link>
        <Text size="md" as="p" mb="xl">
          The RIA is a tool designed by UNDP that provides an overview of the
          level of alignment between national plans/strategies, sectoral plans
          and policies with the SDG targets.
        </Text>
        <Link href="https://www.undp.org/content/dam/undp/library/SDGs/English/SDG_Accelerator_and_Bottleneck_Assessment_Tool.pdf">
          SDG Acceleration and Bottleneck Assessment (ABA)
        </Link>
        <Text size="md" as="p" mb="xl">
          The ABA aims to support countries to identify catalytic policy and/or
          programme areas or ‘accelerators’ that can trigger positive multiplier
          effects across the SDGs and targets, and solutions to bottlenecks that
          impede the optimal performance of the interventions that enable the
          identified accelerators.
        </Text>
        <Link href="https://sustainabledevelopment.un.org/content/documents/21103UNDP_SDG_integration_tools_overview_2pager.pdf">
          UNDP Support to Implementation of the Sustainable Development Goals
          toolkit
        </Link>
        <Text size="md" as="p" mb="xl">
          In addition to the tools mentioned above, this list includes tools to
          support SDG assessments, implementation, monitoring and evaluation,
          and reporting.
        </Text>
        <Text size="xl" as="h3" fontWeight="bold" mt="4xl" mb="lg">
          Resources to support international commitments
        </Text>
        <Link href="https://www.ndc-cluster.net/toolbox">
          NDC Cluster web site
        </Link>
        <Text size="md" as="p" mb="xl">
          The web site is sponsored by the German government to support
          Nationally Determined Contributions globally. It features a wide range
          of tools to support various analyses supporting NDC implementation
          including the development of a national tracking and accounting
          system.
        </Text>
        <Link href="https://urldefense.proofpoint.com/v2/url?u=https-3A__www.afdb.org_fileadmin_uploads_afdb_Documents_Generic-2DDocuments_African-5FNDCs-5FGap-5FAnalysis-5FReport.pdf&d=DwMGaQ&c=euGZstcaTDllvimEN8b7jXrwqOf-v5A_CdpgnVfiiMM&r=RZOoXWEoGMKJELX_O8KSOWuRrTmBL0p6WvNZ5x-CJL0&m=lA5lPPQD-jAewywVCNLgyT8bdoMgh3KjXc49Rp5Z5Lg&s=CwDu3c-YXZtuEOV2grRZZW0steBXFgfOt9aqpUf_VcE&e=">
          African NDCs Gap Analysis Report
        </Link>
        <Text size="md" as="p" mb="xl">
          This African Development Bank gap analysis report from 2018 examines
          the gaps that exist within the context of implementing NDCs in Africa.
          It aims to identify key elements for success as well as barriers and
          opportunities to the achievement of NDC targets.
        </Text>
        <Link href="https://www.undrr.org/developing-national-disaster-risk-reduction-strategies">
          Developing National Disaster Risk Strategies
        </Link>
        <Text size="md" as="p" mb="xl">
          These guidelines from the UN Office for Disaster Risk Reduction offer
          practical guidance and good practice examples for aligning a national
          disaster risk reduction strategy with the Sendai Framework for
          Disaster Risk Reduction 2015-2030.
        </Text>
        <Link href="https://unsdg.un.org/resources/leaving-no-one-behind-unsdg-operational-guide-un-country-teams-interim-draft">
          Leave No One Behind - Operational guide for UN Country Teams
        </Link>
        <Text size="md" as="p" mb="xl">
          This Operational Guide has been developed by the United Nations
          Sustainable Development Group to strengthen the United Nations’
          support of implementation of the 2030 Agenda’s commitment to LNOB at
          the national level. The Guide provides a step-by-step approach to
          operationalizing Member States’ pledge to leave no one behind and
          reach the furthest behind first. It is a resource that may be adapted
          by national development planners to mainstream LNOB in the NDP
          planning process.
        </Text>
        <Link href="https://www.odi.org/publications/10859-leave-no-one-behind-index">
          The ‘Leave No One Behind’ Index
        </Link>
        <Text size="md" as="p" mb="xl">
          The ‘leave no one behind’ index monitors the extent to which the 44
          governments presenting their National Voluntary Reviews at the 2017
          High Level Political Forum are set up to meet their commitments to
          'leave no one behind'. The index measures governments’ readiness in
          three areas.
        </Text>
        <Link href="https://www.undp.org/content/dam/somalia/docs/Project_Documents/Womens_Empowerment/Gender%20Mainstreaming%20Made%20Easy_Handbook%20for%20Programme%20Staff1.pdf">
          UNDP Handbook on Gender Mainstreaming
        </Link>
        <Text size="md" as="p" mb="xl">
          The Handbook contains specialised tools for gender mainstreaming
          including deep dive gender analyses as an input for formulating
          programmes. It builds on training materials for United Nations staff
          but can be adapted and used as a resource for NDP formulation.
        </Text>
        <Link
          href="https://www4.unfccc.int/sites/ndcstaging/Pages/Home.aspx "
          mt="xl"
        >
          The UNFCCC’ NDC Registry
        </Link>
        <Text size="xl" as="h3" fontWeight="bold" mt="4xl" mb="lg">
          Statistics
        </Text>
        <Link href="https://www.afdb.org/en/knowledge/statistics">
          African Development Bank Statistics Pocketbook 2017 (English and
          French)
        </Link>
        <Text size="md" as="p" mb="xl">
          Contains an overview of socio-economic data including demography,
          business, infrastructure, and key economic indicators.
        </Text>
        <Link href="https://www.afdb.org/en/knowledge/statistics">
          United Nations Economic Commission for Africa African Centre for
          Statistics
        </Link>
        <Text size="md" as="p" mb="xl">
          The portal offers a wide range of resources on statistics, including
          the Africa Statistics Flash, which contains macroeconomic indicators,
          key social indicators and information on trade, foreign direct
          investment and official development assistance.
        </Text>
        <Text size="xl" as="h3" fontWeight="bold" mt="4xl" mb="lg">
          Crisis and fragility assessments
        </Text>
        <Box mb="xl">
          <Link href="https://www.pbsbdialogue.org/media/filer_public/96/fb/96fb5ae4-7b0d-4007-bf9e-1ed869db21da/rd_4_fragility_assessment_guidance_note_final.pdf">
            Guidance Note on Fragility Assessment
          </Link>
        </Box>
        <Link href="https://www.undp.org/content/undp/en/home/librarypage/democratic-governance/conflict-prevention/conducting-a-conflict-and-development-analysis.html">
          Conflict and Development Analysis tool
        </Link>
        <Text size="md" as="p" mb="xl">
          The CDA tool provides guidance on conducting conflict analysis and
          applying the findings of analysis for a range of purposes. The CDA
          presents an approach to conflict analysis that assists in the
          gathering of information, brings structure to the analysis and leads
          to a strong and methodically substantive understanding of a context
          with the goal of supporting evidence-based decision-making.
        </Text>
        <Link href="https://www.worldbank.org/en/topic/fragilityconflictviolence/brief/recovery-and-peace-building-assessments">
          Recovery and Peace Building Assessments
        </Link>
        <Text size="md" as="p" mb="xl">
          The Recovery and Peace Building Assessment (RPBA) is a partnership
          framework supported by the European Union, the United Nations and the
          World Bank Group (WBG) to coordinate reengagement in countries or
          regions emerging from conflict or political crisis. It aims to ensure
          that international and local interventions for recovery are aligned by
          helping countries assess, plan and prioritize requirements over time
          under a common process. A shared understanding of the context of
          conflict, crisis and instability is essential for peace to be
          sustainable.
        </Text>
        <Link href="https://www.undp.org/content/undp/en/home/librarypage/crisis-prevention-and-recovery/pdna.html">
          Post Disaster Needs Assessment
        </Link>
        <Text size="md" as="p" mb="xl">
          The tool consists of two guides for conducting Post Disaster Needs
          Assessments (PDNA) and for preparing Disaster Recovery Frameworks
          (DRF). Both guides are based on good practices and experiences from
          around the world and are intended to coalesce international and local
          support behind a single, government-led post disaster recovery
          process. The PDNA Guidelines and the DRF Guide are complementary tools
          that are designed to avoid the duplication of efforts, streamline the
          recovery process and provide an evidence base for resource
          mobilization.
        </Text>
        <Text size="xl" as="h3" fontWeight="bold" mt="4xl" mb="lg">
          Modelling and Foresight Tools
        </Text>
        <Link href="https://www.millennium-institute.org/isdg">
          Integrated Sustainable Development Goals (iSDG) Model
        </Link>
        <Text size="md" as="p" mb="xl">
          The Integrated Sustainable Development Goals (iSDG) model is a policy
          simulation tool designed to help policy makers and other stakeholders
          make sense of the complex web of interconnections between the SDGs.
          The tool focuses on the dynamic interactions within the SDG system to
          reveal the best paths and progression towards achieving the SDGs.
        </Text>
        <Link href="https://pardee.du.edu/">International Futures (IFs)</Link>
        <Text size="md" as="p" mb="xl">
          IFs is a computer programme that is used to explore how countries and
          regions have developed in the past, how they are expected to grow and
          change in the future, and what might happen due to policy choices or
          possible disruptive events. The tool connects 12 major integrated
          submodules: agriculture, demographics, economics, education, energy,
          environment, government finance, governance, health, infrastructure,
          international politics and technology.
        </Text>
        <Link href="www.osimosys.org/">
          Climate, Land Energy Water System (CLEWS)
        </Link>
        <Text size="md" as="p" mb="xl">
          This tool developed by UNDESA enables the simultaneous consideration
          of the interlinkages between resource systems; food, energy and water
          security. It can analyse connections across policies for SDGs and
          Nationally Determined Contributions (NDCs) under the Paris Agreement
          and identify where pressure points exist, and how to minimize
          trade-offs while potentiating synergies.
        </Text>
        <Link href="https://www.undp.org/content/undp/en/home/librarypage/capacity-building/global-centre-for-public-service-excellence/ForesightManual2018.html">
          Foresight Manual: Empowered Futures for the 2030 Agenda
        </Link>
        <Text size="md" as="p" mb="xl">
          This Manual was developed by UNDP’s Global Centre for Public Service
          Excellence to provide future context analysis within the realm of
          forecasting and foresight, relying on emerging and probabilistic data.
          Strategic foresight is applied due to “the capacity to think
          systematically about the future to inform decision making today” and
          is a means to capture an understanding of the historical, present and
          future situation. The manual introduces several foresight techniques
          for strategic planning.
        </Text>
        <Link href="https://www.shapingtomorrow.com/media-centre/pf-ch03.pdf">
          Jackson’s Practical Foresight Guide
        </Link>
        <Text size="md" as="p" mb="xl">
          The Guide was prepared by a private consultancy firm and contains a
          detailed overview of various techniques mentioned in the Foresight
          Manual and outline their benefits and disadvantages vis-à-vis other
          tools.
        </Text>
        <Link href="https://sdgimpactassessmenttool.org/about">
          SDG Impact Assessment Tool
        </Link>
        <Text size="md" as="p" mb="xl">
          The SDG Impact Assessment Tool was developed by the Gothenburg Centre
          for Sustainable Development. It is a free online learning tool that
          visualizes the results from a self-assessment of how an activity,
          organisation or innovation affects the SDGs. It aims to stimulate the
          user to get a better understanding of the complexity of sustainable
          development and the different aspects of the SDGs
        </Text>
        <Text size="xl" as="h3" fontWeight="bold" mt="4xl" mb="lg">
          Risk Management Tools
        </Text>
        <Link href="https://www.undrr.org/developing-national-disaster-risk-reduction-strategies">
          Developing National Disaster Risk Strategies
        </Link>
        <Text size="md" as="p" mb="xl">
          These guidelines from the UN Office for Disaster Risk Reduction offer
          practical guidance and good practice examples for aligning a national
          disaster risk reduction strategy with the Sendai Framework for
          Disaster Risk Reduction 2015-2030. For the integrated future analysis,
          planners are advised to consult Step 2 of the guidelines and produce a
          high-level objectives proposal to be integrated in Step 3 of this
          Module.
        </Text>
        <Link href="https://www.oecd.org/dac/Resilience Systems Analysis FINAL.pdf">
          Resilience Systems Analysis (RSA)
        </Link>
        <Text size="md" as="p" mb="xl">
          RSA is a tool developed by the OECD that helps effectively integrate
          risk and resilience into the NDP process and help countries prepare
          for events ranging from rare (so-called ‘Black Swans’) to seasonal,
          such as pandemics, volcanic eruptions, earthquakes, currency
          devaluations, diseases, flooding, drought, as well as trends such as
          deforestation, increased pollution, and stress related to political
          events.
        </Text>
        <Text size="xl" as="h3" fontWeight="bold" mt="4xl" mb="lg">
          Tools to help you formulate a Theory of Change
        </Text>
        <Link href="https://www.theoryofchange.org/wp-content/uploads/toco_library/pdf/UNDERSTANDINGTHEORYOFChangeSteinValtersPN.pdf">
          Understanding Theories of Change in International Development
        </Link>
        <Text size="md" as="p" mb="xl">
          The Asia Foundation, 2012
        </Text>
        <Link href="https://unsdg.un.org/sites/default/files/16.-2016-10-18-Guidance-on-ToC-PSG-LAC.pdf">
          Theory of Change Concept note
        </Link>
        <Text size="md" as="p" mb="xl">
          United Nations Development Group, 2016
        </Text>
        <Link href="https://www.unep.org/pt-br/node/16893">
          Theory of Change
        </Link>
        <Text size="md" as="p" mb="xl">
          UN Environment Programme 2020
        </Text>
        <Link href="https://assets.publishing.service.gov.uk/media/57a08a66ed915d622c000703/Appendix_3_ToC_Examples.pdf">
          Examples of Theories of Change
        </Link>
        <Text size="md" as="p" mb="xl">
          Department of International Development 2012
        </Text>
        <Text size="xl" as="h3" fontWeight="bold" mt="4xl" mb="lg">
          Tools and Resources to help you reflect your International Commitments
          in the Strategic Plan
        </Text>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Link
            mb="md"
            href="https://unfccc.int/process-and-meetings/the-paris-agreement/the-paris-agreement"
          >
            The Paris Agreement
          </Link>
          <Link
            mb="md"
            href="https://www.preventionweb.net/files/43291_sendaiframeworkfordrren.pdf"
          >
            Sendai Framework for Disaster Risk Reduction 2015- 2030
          </Link>
          <Link
            mb="md"
            href="https://www.un.org/en/events/pastevents/pdfs/Beijing_Declaration_and_Platform_for_Action.pdf"
          >
            1995 Beijing Platform for Action
          </Link>
          <Link mb="md" href="https://sdgs.un.org/goals/goal5">
            Resources on Goal 5 – Achieve Gender Equality and Empower all women
            and girls
          </Link>
          <Link
            mb="md"
            href="https://unsdg.un.org/2030-agenda/universal-values/leave-no-one-behind"
          >
            Resources on Leave No One Behind Principle
          </Link>
          <Link
            mb="md"
            href="https://www.un.org/sustainabledevelopment/peace-justice/"
          >
            Resources on Goal 16 – Promote Just, Peaceful and Inclusive
            Societies
          </Link>
        </Box>
        <Text size="xl" as="h3" fontWeight="bold" mt="4xl" mb="lg">
          Tools to help you build an Operational Plan
        </Text>
        <Link href="https://www.undp.org/publications/mainstreaming-environment-and-climate-poverty-reduction-and-sustainable-development">
          Mainstreaming Environment and Climate for Poverty Reduction and
          Sustainable Development
        </Link>
        <Text size="md" as="p" mb="xl">
          This handbook is designed as guidance for policymakers and
          practitioners to mainstream pro-poor environment and climate concerns
          into planning, budgeting and monitoring. Mainstreaming is achieved by
          putting poverty-environment issues at the heart of government—in other
          words, by taking these issues into mainstream economic decision-making
          processes, particularly  the national and subnational planning and
          budgeting processes led by ministries of finance, planning and local
          government, and supported by ministries of environment.
        </Text>
        <Link href="https://transparency-partnership.net/sites/default/files/u2612/6-undp_support_toolsl_kader_26.04.pdf">
          UNDP tools to support NDC implementation
        </Link>
        <Text size="md" as="p" mb="xl">
          A compilation of tools to support various dimensions of NDC
          implementation, including gender, national mitigations strategies,
          finance and public expenditures.
        </Text>
        <Link href="https://www.preventionweb.net/publication/report-open-ended-intergovernmental-expert-working-group-indicators-and-terminology">
          Report of the open-ended intergovernmental expert working group on
          indicators and terminology relating to disaster risk reduction
        </Link>
        <Text size="md" as="p" mb="xl">
          This report presents recommended indicators to monitor the global
          targets of the Sendai Framework, the follow-up to and
          operationalization of the indicators and recommended terminology
          relating to disaster risk reduction.
        </Text>
        <Link href="https://www.globalsupportprogramme.org/nap-gsp/resources?field_resource_type_tid=723&field_region_tid=811">
          Support to climate change adaptation
        </Link>
        <Text size="md" as="p" mb="xl">
          This resource includes tools, case studies and briefs from various
          countries compiled by UNDP, providing useful examples of formulation
          and implementation of National Adaptation Plans.
        </Text>
        <Link href="https://www.afdb.org/fileadmin/uploads/afdb/Documents/Generic-Documents/Analysis_of_Adaptation_Components_in_African_NDCs_2019.pdf">
          Analysis of adaptation components of NDCs
        </Link>
        <Text size="md" as="p" mb="xl">
          A resource by the African Development Bank that analyses African NDCs
          and provides useful recommendations and case studies.
        </Text>
        <Link href="https://www.afdb.org/fileadmin/uploads/afdb/Documents/Generic-Documents/African_NDCs_Gap_Analysis_Report.pdf">
          African NDCs Gap Analysis Report
        </Link>
        <Text size="md" as="p" mb="xl">
          This report by the African Development Bank includes a literature
          review on climate action, a review of African NDCs and identify NDCs
          implementation gaps.
        </Text>
        <Link href="https://repository.uneca.org/handle/10855/41804">
          Economic Report on Africa 2019 – Fiscal Policy for Sustainable
          Development in Africa
        </Link>
        <Text size="md" as="p" mb="xl">
          This report by the United Nations Economic Commission for Africa
          examines the institutional and policy reforms required to enable
          African countries to maximize domestic resource mobilization. The
          report focuses on the instrumental role of fiscal policy in crowding
          in investment and creating adequate fiscal space for social policy,
          including supporting women and youth-led small and medium enterprises.
        </Text>
        <Text size="xl" as="h3" fontWeight="bold" mt="4xl" mb="lg">
          Communication Resources
        </Text>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Link
            mb="md"
            href="https://www.ox.ac.uk/sites/files/oxford/media_wysiwyg/Writing%20a%20communications%20strategy%20%2818.02.16%29.pdf"
          >
            Writing a communications strategy – University of Oxford
          </Link>
          <Link
            mb="md"
            href="https://www.who.int/mediacentre/communication-framework.pdf?ua=1"
          >
            WHO Strategic Communications Framework for effective communications
            - World Health Organization
          </Link>
          <Link
            mb="md"
            href="https://www.gcis.gov.za/sites/default/files/docs/resourcecentre/guidelines/handbook/2006/2006_7.pdf"
          >
            Government Communicators’ Handbook – Republic of South Africa
          </Link>
        </Box>
        <DividerLine my="xl" />
        <Text size="2xl" as="h2" fontWeight="bold" my="xl">
          Examples
        </Text>
        <Text size="md" as="p" mt="xl" fontWeight="bold">
          Module 1
        </Text>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Link href="https://www.dpme.gov.za/keyfocusareas/gwmeSite/The%20PME%20Forum%202018/Concept%20Note%20of%20the%20NDP%205-year%20Implementation%20Plan%202019-2024.pdf">
            Concept Note, South Africa
          </Link>
          <Link href="https://www.gov.ls/wp-content/uploads/2018/04/National-Strategic-Development-Plan-Concept-Note-201213-–-201617.pdf">
            Concept Note, Lesotho’s National Strategic Plan
          </Link>
          <Link href="https://www.ajol.info/index.php/ajer/issue/view/12342">
            African Journal of Economic Review
          </Link>
          <Link href="https://myworld2030.org">My World Survey</Link>
          <Link href="https://www.gov.za/sites/default/files/gcis_document/201409/ndp-2030-our-future-make-it-workr.pdf">
            South Africa National Development Plan
          </Link>
          <Link href="https://www.ng.undp.org/content/nigeria/en/home/presscenter/pressreleases/2019/development-dialogue-series-for-nigeria-launched-to-as-an-initia.html">
            Communications for the SDGs, UNDP Nigeria
          </Link>
        </Box>
        <Text size="md" as="p" mt="xl" fontWeight="bold">
          Module 2
        </Text>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Link href="https://www.mof.go.tz/mofdocs/overarch/Vision2025.pdf">
            Template Tanzania National Development Vision 2025
          </Link>
          <Link href="https://www.gov.za/sites/default/files/gcis_document/201409/devplan2.pdf">
            South Africa National Development Vision 2030
          </Link>
        </Box>
        <Text size="md" as="p" mt="xl" fontWeight="bold">
          Module 3
        </Text>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Link href="https://zambia.unfpa.org/sites/default/files/pub-pdf/Final%207NDP%20Implementation%20Plan%20-%209%20April_2018.pdf">
            Republic of Zambia 7th NDP Implementation Plan 2017-2021,
            “Accelerating Development Efforts toward Vision 2030 without Leaving
            Anyone Behind.
          </Link>
        </Box>
        <Text size="md" as="p" mt="xl" fontWeight="bold">
          Module 4
        </Text>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Link href="https://17d36296-69da-4e74-87fc-d7119a8f2613.filesusr.com/ugd/32519f_fa2d445e7081425e8cc7056dada4db30.pdf">
            Cote d’Ivoire 2016 iSDG Report
          </Link>
          <Link href="https://www.ng.undp.org/content/nigeria/en/home/library/mdg/nigeria-s-integrated-sustainable-development-goals--isdg--model-.html">
            Nigeria 2019 iSDG Report
          </Link>
          <Link href="https://www.eg.undp.org/content/egypt/en/home/library/sustainable-development-goals--sdgs-/sustainable-development-goals-report--egypt-2030.html">
            Sustainable Development Goals Report: Egypt 2030
          </Link>
          <Link href="https://www.ye.undp.org/content/yemen/en/home/library/assesing-the-impact-of--war-on-development-in-yemen-SDGs.html#:~:text=The%20report%20builds%20upon%20previous%20research%2C%20sponsored%20by,the%20impact%20of%20conflict%20on%20development%20in%20Yemen">
            Assessing the Impact of War in Yemen on Achieving the Sustainable
            Development Goals
          </Link>
          <Link href="https://issuu.com/undppublicserv/docs/report_of_the_foresightxchange_work">
            Mauritius's usage of foresight to work Towards an Innovative Civil
            Service in Mauritius
          </Link>
          <Link href="https://www.undp.org/content/dam/undp/library/capacity-development/English/Singapore%20Centre/UNDP-GCPSE_Foresight_for_Africa.pdf">
            Rwanda’s Development through Good Governance - Foresighting Service
            Delivery in Secondary Cities
          </Link>
          <Link
            href="www.oecd.org/dac/conflict-fra-gility-resilience/docs/OECD%20UNICEF%20resilience%20roadmap%20eastern%20DRC%20
          FINAL%20ENGLISH.pdf"
          >
            Resilience Systems Analysis – Eastern Democratic Republic of Congo
          </Link>
          <Link
            href="www.oecd.org/dac/conflict-fragility-resilience/docs/Somalia%20Resilience%20Systems%20
          Analysis%20Feb%202015%20OECD.pdf"
          >
            Resilience Systems Analysis - Somalia
          </Link>
        </Box>
        <Text size="md" as="p" mt="xl" fontWeight="bold">
          Module 5
        </Text>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Link href="https://www.elibrary.imf.org/view/journals/002/2019/218/article-A001-en.xml?print=">
            The Sierra Leone Medium-Term National Development PlanZambia
            National Development Plan
          </Link>
          <Link href="https://www.elibrary.imf.org/view/journals/002/2019/218/article-A001-en.xml?print=&redirect=true">
            Sierra Leone National Development Plan98
          </Link>
          <Link href="https://www.mndp.gov.zm/wp-content/uploads/2018/05/7NDP.pdf">
            Zambia National Development Plan99
          </Link>
          <Link href="http://extwprlegs1.fao.org/docs/pdf/zam170109.pdf">
            Zambia Seventh National Development Plan 2017 – 2021112
          </Link>
          <Link href="https://www.elibrary.imf.org/view/journals/002/2019/218/article-A001-en.xml?print=&redirect=true">
            Sierra Leone Medium-term National Development Plan 2019 - 2023113
          </Link>
        </Box>
      </Box>
    </CenterLayout>
    <Parallax
      scaleFactor={1.25}
      backgroundProps={{
        image: data.sustainableFuture.childImageSharp.gatsbyImageData,
        alt: "Plans for a sustainable future",
      }}
    >
      <CenterLayout py={rem("224px")}>
        <ImgTitle>Plans for a sustainable future</ImgTitle>
        <ImgCopyright>
          Women to have the same opportunities, Togo, Burkina Faso
        </ImgCopyright>
      </CenterLayout>
    </Parallax>
  </Layout>
)

export const pageQuery = graphql`
  {
    sustainableFuture: file(relativePath: { eq: "sustainable-future.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          transformOptions: { fit: CONTAIN, cropFocus: ATTENTION }
          layout: FULL_WIDTH
        )
      }
    }
  }
`

export default RelevantTools
